.App {
  text-align: center;
}
#logo {
  height: 100px;
}
#profpic {
  height: 200px;
  border-style: solid;
  border-width: 3px;
  border-color: rgb(167, 136, 196);
}
#about {
  border-style: solid;
  border-width: 3px;
  border-color: rgb(167, 136, 196);
}
#nomnom {
  height: 125px;
  border-style: solid;
  border-width: 3px;
  border-color: rgb(167, 136, 196);
  padding: 0;
}

#navbar {
  background-color: rgb(160, 130, 189);
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(160, 130, 189);
  color: white;
  text-align: center;
}

#aboutcontact {
  font-size: 20px;
}
